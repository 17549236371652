import React, { useState, useReducer, useEffect } from 'react';
import { Grid, Divider, Button, Fab, ListItem,ListItemIcon,ListItemText } from '@material-ui/core';
import ArticleCard from '../../utils/articleCard';
import CategoryNavigation from '../navigation/categoryNavigation';
import { Link as RouterLink } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getArticles } from '../../store/actions/article_actions';
// import { clearCurrentArticles } from '../../store/actions/index';
import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import HomeIcon from '@material-ui/icons/Home';
const Home = (props) => {

    let initialSort = { sortBy: "date", order: "desc", limit: 16, skip: 0 };
    const article_type = props.match.params.article_type;
    if (article_type) {
        initialSort['article_type'] = article_type;
    }
    const [sort, setSort] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialSort,
    );
    // const [sort, setSort] = useEffect()

    const articles = useSelector(state => state.articles);
    const dispatch = useDispatch();
    const [hasMore, setHasMore] = useState(true);
    const [isBottom, setIsBottom] = useState(false);


    useEffect(() => {
        // trigger only on first render
        if (articles && !articles.articles) {
            ///dispatch
            dispatch(getArticles(initialSort))
            // console.log('fetching init articles');
            // setHasMore(true);
        }


        if (hasMore && articles && articles.articles) {
            // console.log(sort.limit)
            // console.log(sort.skip)
            // console.log(articles.articles.length - sort.skip)
            if (sort.limit > (articles.articles.length - sort.skip)) {
                setHasMore(false);
                setIsBottom(false);
            }
        }
    }, [dispatch, articles]);

    function handleScroll() {
        const scrollTop = (document.documentElement
            && document.documentElement.scrollTop)
            || document.body.scrollTop;
        const scrollHeight = (document.documentElement
            && document.documentElement.scrollHeight)
            || document.body.scrollHeight;
        if (scrollTop + window.innerHeight + 500 >= scrollHeight) {
            setIsBottom(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (isBottom) {
            if (hasMore) {
                // console.log('has more');
                let skip = sort.skip + sort.limit;
                dispatch(getArticles({ ...sort, skip: skip }));
                setSort({ skip: skip })
                setIsBottom(false);
            } else {
                console.log('no more');
            }
        }
    }, [isBottom]);
    // console.log(hasMore);
    // console.log(isBottom);

    let article_type_label;
    switch (article_type) {
        case 'movie':
            article_type_label = "电影"
            break;
        case 'tvseries':
            article_type_label = "海外剧"
            break;
        case 'varietyshow':
            article_type_label = "综艺"
            break;
        case 'anime':
            article_type_label = "动漫"
            break;
        case 'chstvseries':
            article_type_label = "国产剧"
            break;
        case 'english':
            article_type_label = "英文学习"
            break;
        default:
            article_type_label = '';
    }

    const useStyles = makeStyles((theme) => ({
        button: {
            minWidth: '48px',
        },
        fab_uncheacked: {
            // margin: theme.spacing(1),
            marginRight: '3px',
            backgroundColor: '#F4F4F4',
        },
        fab_cheacked: {
            // margin: theme.spacing(1),
            marginRight: '3px',
            backgroundColor: 'orangered',
            color: 'white',
        }
    }));

    const classes = useStyles();
    return (
        <div>
            <div style={{ marginBottom: '10px'}}>
                <Fab
                    variant="extended"
                    size="small"
                    href="/"
                    className={
                        !article_type ?
                            classes.fab_cheacked :
                            classes.fab_uncheacked
                    }
                    style={{ minWidth: '45px' }}>
                    首页
                </Fab>
                <Fab
                    variant="extended"
                    size="small"
                    href="/movie"
                    className={
                        article_type === 'movie' ?
                            classes.fab_cheacked :
                            classes.fab_uncheacked
                    }
                    style={{ minWidth: '45px' }}>
                    电影
                </Fab>
                <Fab
                    variant="extended"
                    size="small"
                    href="/tvseries"
                    className={
                        article_type === 'tvseries' ?
                            classes.fab_cheacked :
                            classes.fab_uncheacked
                    }
                    style={{ minWidth: '45px' }}>
                    海外剧
                </Fab>
                <Fab
                    variant="extended"
                    size="small"
                    href="/varietyshow"
                    className={
                        article_type === 'varietyshow' ?
                            classes.fab_cheacked :
                            classes.fab_uncheacked
                    }
                    style={{ minWidth: '45px' }}>
                    综艺
                </Fab>
                <Fab
                    variant="extended"
                    size="small"
                    href="/anime"
                    className={
                        article_type === 'anime' ?
                            classes.fab_cheacked :
                            classes.fab_uncheacked
                    }
                    style={{ minWidth: '45px' }}>
                    动漫
                </Fab>
                <Fab
                    variant="extended"
                    size="small"
                    href="/chstvseries"
                    className={
                        article_type === 'chstvseries' ?
                            classes.fab_cheacked :
                            classes.fab_uncheacked
                    }
                    style={{ minWidth: '45px' }}>
                    国产剧
                </Fab>
                <Fab
                    variant="extended"
                    size="small"
                    href="/english"
                    className={
                        article_type === 'english' ?
                            classes.fab_cheacked :
                            classes.fab_uncheacked
                    }
                    style={{ minWidth: '45px' }}>
                    英文
                </Fab>
            </div>
            {/* <Grid container alignItems="center" >
                <Button className={classes.button} href="/" color="primary" >首页</Button>
                <Divider orientation="vertical" flexItem />
                <Button className={classes.button} color="primary" href="/movies">电影</Button>
                <Button className={classes.button} color="primary">海外剧</Button>
                <Button className={classes.button} color="primary">综艺</Button>
                <Button className={classes.button} color="primary">动漫</Button>
                <Button className={classes.button} color="primary">国产剧</Button>
                <Button className={classes.button} color="primary">英文</Button>
            </Grid> */}
            {/* <Divider /> */}
            <div style={{ height: '15px' }}>
                <Divider />
            </div>
            {
                article_type ?
                    <h4>{article_type_label}</h4>
                    : ''
            }
            <div>
                <Grid container spacing={2} className="article_card">
                    {articles && articles.articles ?
                        articles.articles.map((item) => (
                            <Grid key={item._id} item xs={12} sm={6} lg={3}>
                                <ArticleCard key={item._id} article={item} />
                            </Grid>
                        ))
                        : null}
                </Grid>
                {/* <button
                    onClick={() => {
                        let skip = sort.skip + sort.limit;
                        dispatch(getArticles({ ...sort, skip: skip }));
                        setSort({ skip: skip })
                    }}
                >
                    Load more
                </button> */}
                {/* <InfiniteScroll
                    pageStart={0}
                    loadMore={
                        () => {
                            let skip = sort.skip + sort.limit;
                            dispatch(getArticles({ ...sort, skip: skip }));
                            setSort({ skip: skip })
                        }
                    }
                    hasMore={hasMore}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                >
                    {'aa'}
                </InfiniteScroll> */}
                {
                    hasMore &&
                    <div style={{ display: 'flex', marginTop: '50px', height: '60px' }}>
                        <CircularProgress style={{margin: 'auto'}}/>
                    </div>
                }
            </div>
        </div>
    )
}

export default Home;
