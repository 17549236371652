import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { changeUsername, changeUserPassword } from '../../store/actions/users_actions';
import { TextField, Button } from '@material-ui/core';

const AuthProfile = (props) => {
    const notifications = useSelector(state => state.notifications)
    const username = useSelector(state => state.users.data.username);
    const dispatch = useDispatch();

    const [newUserName, setNewUserName] = useState('');
    const [newUserNameError, setNewUserNameError] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');

    const handleSubmitNewUserName = () => {
        if (newUserName.length < 3) {
            setNewUserNameError('用户名要2个字母以上');
            return;
        }
        dispatch(changeUsername({
            username: username,
            newusername: newUserName,
        }));
    }

    const handleSubmitNewPassword = () => {
        if (newPassword.length < 5) {
            setNewPasswordError('密码要4个字母以上');
            return;
        }
        dispatch(changeUserPassword({
            username: username,
            newpassword: newPassword,
        }));
    }

    useEffect(() => {
        setNewUserName('');
        setNewPassword('');
    }, [notifications, props.history])

    return (
        <div className="auth_container">
            <h1 style={{ fontFamily: 'cursive' }}>
                修改账号密码
            </h1>
            <>
                <div>
                    <p style={{ textAlign: 'center' }}>{'现在用户名: ' + username}</p>
                </div>
                <div className="form-group">
                    <TextField
                        style={{ width: '100%', backgroundColor: 'antiquewhite' }}
                        name="username"
                        label="新用户名"
                        variant="outlined"
                        value={newUserName}
                        onChange={(event) => {
                            setNewUserName(event.target.value);
                            setNewUserNameError('');
                        }}
                        helperText={newUserNameError}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        onClick={handleSubmitNewUserName}
                    >
                        {'修改'}
                    </Button>
                </div>
                <div style={{ height: '20px', }}>
                </div>
                <div className="form-group">
                    <TextField
                        style={{ width: '100%', backgroundColor: 'antiquewhite' }}
                        name="password"
                        label="新密码"
                        variant="outlined"
                        value={newPassword}
                        onChange={(event) => {
                            setNewPassword(event.target.value);
                            setNewPasswordError('');
                        }}
                        helperText={newPasswordError}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        onClick={handleSubmitNewPassword}
                    >
                        {'修改'}
                    </Button>
                </div>
            </>
        </div>
    )
}

export default AuthProfile;
