import React from 'react';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

const MainLayout = (props) => {
    const site = useSelector(state => state.site)
    let style = {};
    if (window.location.href.includes("/auth")) {
        style = {
            backgroundImage: "url(/1.png)",
            minHeight: '1600px',
            height:'100%',
            backgroundRepeat: 'no-repeat',
        };
    }

    let class_name = `app_container mb-5 ${site.layout}`;
    if (window.location.href.includes("/dashboard")) {
        class_name = `app_container_margin mb-5 ${site.layout}`;
    }
    // console.log(`${site.layout}`);
    // class_name = `app_container_margin mb-5 dash_layout`;

    return (
        <Container
            className={class_name}
            style={style}
        >
            {props.children}
            <ToastContainer />
        </Container>
    )
}

export default MainLayout;
