import React, { useState, useEffect, useRef } from 'react';
import AdminLayout from '../../../hoc/adminLayout';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { addArticle, getCategories } from '../../../store/actions/article_actions';
import { validation, formValues } from './validationSchema';
import Loader from '../../../utils/loader';
import { Container, Draggable } from "react-smooth-dnd";

import {
    TextField,
    Button,
    Divider,
    Chip,
    Paper,
    InputBase,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,

    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemIcon,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import ClearIcon from '@material-ui/icons/Clear';
import arrayMove from "array-move";


const AddArticle = (props) => {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notifications);
    const { categories } = useSelector(state => state.articles);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editorBlur, setEditorBlur] = useState(false);
    const actorsValue = useRef('');
    const videoURLValue = useRef('');
    const imageURLValue = useRef('');
    const engSubURLValue = useRef('');
    const chsSubURLValue = useRef('');

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formValues,
        validationSchema: validation,
        onSubmit: (values, { resetForm }) => {
            values['videos'] = videos;
            values['images'] = images;
            values['eng_subs'] = engSubs;
            values['chs_subs'] = chsSubs;
            values['fanti_subs'] = fantiSubs;
            values['chs_eng_subs'] = chsEngSubs
            values['eng_chs_subs'] = engChsSubs;
            values['video_labels'] = videoLabels;

            values['article_type'] = articleType;
            setIsSubmitting(true);
            dispatch(addArticle(values))
        }
    });


    const handleEditorState = (state) => {
        formik.setFieldValue('content', state, true)
    }

    const handleEditorBlur = (blur) => {
        setEditorBlur(true)
    }


    const errorHelper = (formik, values) => ({
        error: formik.errors[values] && formik.touched[values] ? true : false,
        helperText: formik.errors[values] && formik.touched[values] ? formik.errors[values] : null
    });


    useEffect(() => {
        if (notifications && notifications.success) {
            props.history.push('/dashboard/articles');
        }
        if (notifications && notifications.error) {
            setIsSubmitting(false);
        }
    }, [notifications, props.history])

    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch])

    const [articleType, setArticleType] = useState('');
    const [videos, setVideos] = useState([]);
    const [images, setImages] = useState([]);
    const [engSubs, setEngSubs] = useState([]);
    const [chsSubs, setChsSubs] = useState([]);
    const [fantiSubs, setFantiSubs] = useState([]);
    const [chsEngSubs, setChsEngSubs] = useState([]);
    const [engChsSubs, setEngChsSubs] = useState([]);
    const [videoLabels, setVideoLabels] = useState([]);

    // article_type

    useEffect(() => {
        formik.values.videos && setVideos(formik.values.videos);
    }, [formik.values.videos]);
    useEffect(() => {
        formik.values.video_labels && setVideoLabels(formik.values.video_labels);
    }, [formik.values.video_labels]);
    useEffect(() => {
        formik.values.article_type && setArticleType(formik.values.article_type);
    }, [formik.values.article_type]);
    useEffect(() => {
        formik.values.images && setImages(formik.values.images);
    }, [formik.values.images]);
    useEffect(() => {
        formik.values.chs_subs && setChsSubs(formik.values.chs_subs);
    }, [formik.values.chs_subs]);
    useEffect(() => {
        formik.values.eng_subs && setEngSubs(formik.values.eng_subs);
    }, [formik.values.eng_subs]);
    useEffect(() => {
        formik.values.fanti_subs && setFantiSubs(formik.values.fanti_subs);
    }, [formik.values.fanti_subs]);
    useEffect(() => {
        formik.values.chs_eng_subs && setChsEngSubs(formik.values.chs_eng_subs);
    }, [formik.values.chs_eng_subs]);
    useEffect(() => {
        formik.values.eng_chs_subs && setEngChsSubs(formik.values.eng_chs_subs);
    }, [formik.values.eng_chs_subs]);


    useEffect(() => {
        let tmp;

        if (chsSubs.length < videos.length) {
            tmp = chsSubs.slice().concat(new Array((videos.length - chsSubs.length)).fill(''));
            setChsSubs(tmp);
        }

        if (engSubs.length < videos.length) {
            tmp = engSubs.slice().concat(new Array((videos.length - engSubs.length)).fill(''));
            setEngSubs(tmp);
        }

        if (videoLabels.length < videos.length) {
            tmp = engSubs.slice().concat(new Array((videos.length - videoLabels.length)).fill(''));
            setVideoLabels(tmp);
        }

        if (fantiSubs.length < videos.length) {
            tmp = fantiSubs.slice().concat(new Array((videos.length - fantiSubs.length)).fill(''));
            setFantiSubs(tmp);
        }

        if (chsEngSubs.length < videos.length) {
            tmp = chsEngSubs.slice().concat(new Array((videos.length - chsEngSubs.length)).fill(''));
            setChsEngSubs(tmp);
        }

        if (engChsSubs.length < videos.length) {
            tmp = engChsSubs.slice().concat(new Array((videos.length - engChsSubs.length)).fill(''));
            setEngChsSubs(tmp);
        }
    }, [videos]);

    const onImageDrop = ({ removedIndex, addedIndex }) => {
        setImages(items => arrayMove(items, removedIndex, addedIndex));
    };
    const onVideoDrop = ({ removedIndex, addedIndex }) => {
        setVideos(items => arrayMove(items, removedIndex, addedIndex));
        setChsSubs(items => arrayMove(items, removedIndex, addedIndex));
        setEngSubs(items => arrayMove(items, removedIndex, addedIndex));
        setFantiSubs(items => arrayMove(items, removedIndex, addedIndex));
        setChsEngSubs(items => arrayMove(items, removedIndex, addedIndex));
        setEngChsSubs(items => arrayMove(items, removedIndex, addedIndex));
    };

    return (
        <AdminLayout section="Add article">
            { isSubmitting ?
                <Loader />
                :
                <form className="mt-3 article_form" onSubmit={formik.handleSubmit}>

                    <div className="form-group">
                        <TextField
                            style={{ width: '100%' }}
                            name="title"
                            label="Enter a title"
                            variant="outlined"
                            {...formik.getFieldProps('title')}
                            {...errorHelper(formik, 'title')}
                        />
                    </div>

                    <div className="form-group">
                        <TextField
                            style={{ width: '100%' }}
                            name="content"
                            label="Enter content"
                            variant="outlined"
                            {...formik.getFieldProps('content')}
                            {...errorHelper(formik, 'content')}
                            multiline
                            rows={4}
                        />
                    </div>


                    <Divider className="mt-3 mb-3" />

                    <div className="form-group">
                        <h5>Image List</h5>
                        <div>
                            <Paper className="actors_form">
                                <InputBase
                                    inputRef={imageURLValue}
                                    className="input"
                                    placeholder="Add Image URL here"
                                />
                                <IconButton
                                    onClick={() => {
                                        // console.log(images);
                                        setImages(images => [...images, imageURLValue.current.value]);
                                        imageURLValue.current.value = '';
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Paper>
                            <List>
                                <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onImageDrop}>
                                    {images && images.map((url, index) => (
                                        <Draggable key={index}>
                                            <ListItem>
                                                <ListItemText primary={url} style={{ color: 'blue' }} />
                                                <ListItemSecondaryAction>
                                                    <ListItemIcon onClick={() => {
                                                        setImages(images.filter(item => item != url));
                                                    }}>
                                                        <ClearIcon />
                                                    </ListItemIcon>
                                                    <ListItemIcon className="drag-handle">
                                                        <DragHandleIcon />
                                                    </ListItemIcon>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </Draggable>
                                    ))}
                                </Container>
                            </List>
                        </div>
                    </div>


                    <div className="form-group">
                        <h5>Video List</h5>
                        <div>
                            <Paper className="actors_form">
                                <InputBase
                                    inputRef={videoURLValue}
                                    className="input"
                                    placeholder="Add video URL here"
                                />
                                <IconButton
                                    onClick={() => {
                                        setVideos(videos => [...videos, videoURLValue.current.value]);
                                        videoURLValue.current.value = '';
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Paper>
                            <List>
                                <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onVideoDrop}>
                                    {videos && videos.map((url, index) => (
                                        <Draggable key={index}>
                                            <ListItem>
                                                {/* <ListItemText primary={url} style={{ color: 'blue' }} /> */}
                                                <div style={{display:'flex', color: 'black'}}>
                                                    <p>{index + 1}</p>
                                                    <TextField
                                                        size="small"
                                                        style={{maxWidth: '250px'}}
                                                        label="Video"
                                                        variant="outlined"
                                                        value={videos[index]}
                                                        onChange={(event) => {
                                                            let tmp_videos = videos.slice();
                                                            tmp_videos[index] = event.target.value;
                                                            setVideos(tmp_videos);
                                                        }}
                                                    />
                                                    <TextField
                                                        size="small"
                                                        style={{maxWidth: '250px'}}
                                                        label="Label"
                                                        variant="outlined"
                                                        value={videoLabels[index]}
                                                        onChange={(event) => {
                                                            let tmp = videoLabels.slice();
                                                            tmp[index] = event.target.value;
                                                            setVideoLabels(tmp);
                                                        }}
                                                    />
                                                    <TextField
                                                        size="small"
                                                        style={{maxWidth: '150px'}}
                                                        label="中文字幕(简体)"
                                                        variant="outlined"
                                                        value={chsSubs[index]}
                                                        onChange={(event) => {
                                                            let tmp = chsSubs.slice();
                                                            tmp[index] = event.target.value;
                                                            setChsSubs(tmp);
                                                        }}
                                                    />
                                                    <TextField
                                                        size="small"
                                                        style={{maxWidth: '100px'}}
                                                        label="English"
                                                        variant="outlined"
                                                        value={engSubs[index]}
                                                        onChange={(event) => {
                                                            let tmp = chsSubs.slice();
                                                            tmp[index] = event.target.value;
                                                            setEngSubs(tmp);
                                                        }}
                                                    />
                                                    <TextField
                                                        size="small"
                                                        style={{maxWidth: '150px'}}
                                                        label="繁体字幕"
                                                        variant="outlined"
                                                        value={fantiSubs[index]}
                                                        onChange={(event) => {
                                                            let tmp = chsSubs.slice();
                                                            tmp[index] = event.target.value;
                                                            setFantiSubs(tmp);
                                                        }}
                                                    />
                                                    <TextField
                                                        size="small"
                                                        style={{maxWidth: '150px'}}
                                                        label="中上英下"
                                                        variant="outlined"
                                                        value={chsEngSubs[index]}
                                                        onChange={(event) => {
                                                            let tmp = chsSubs.slice();
                                                            tmp[index] = event.target.value;
                                                            setChsEngSubs(tmp);
                                                        }}
                                                    />
                                                    <TextField
                                                        size="small"
                                                        style={{maxWidth: '150px'}}
                                                        label="英上中下"
                                                        variant="outlined"
                                                        value={engChsSubs[index]}
                                                        onChange={(event) => {
                                                            let tmp = chsSubs.slice();
                                                            tmp[index] = event.target.value;
                                                            setEngChsSubs(tmp);
                                                        }}
                                                    />
                                                </div>
                                                <ListItemSecondaryAction>
                                                    <ListItemIcon onClick={() => {
                                                        setVideos(videos.filter(item => item !== url));
                                                    }}>
                                                        <ClearIcon />
                                                    </ListItemIcon>
                                                    <ListItemIcon className="drag-handle">
                                                        <DragHandleIcon />
                                                    </ListItemIcon>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </Draggable>
                                    ))}
                                </Container>
                            </List>
                        </div>
                    </div>

                    <Divider className="mt-3 mb-3" />

                    <h5>Select a video type</h5>
                    <FormControl style={{minWidth: '120px'}}>
                        <Select
                            value={articleType}
                            onChange={(event)=> {setArticleType(event.target.value)}}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value={'movie'}>Movie</MenuItem>
                            <MenuItem value={'tvseries'}>TV Series</MenuItem>
                            <MenuItem value={'varietyshow'}>Variety Show</MenuItem>
                            <MenuItem value={'anime'}>Anime</MenuItem>
                            <MenuItem value={'chstvseries'}>Chinese TV Series</MenuItem>
                            <MenuItem value={'english'}>English</MenuItem>
                        </Select>
                    </FormControl>

                    <Divider className="mt-3 mb-3" />

                    <h5>Movie data and score</h5>
                    <div className="form-group">
                        <TextField
                            style={{ width: '100%' }}
                            name="score"
                            label="Enter a score"
                            variant="outlined"
                            {...formik.getFieldProps('score')}
                            {...errorHelper(formik, 'score')}
                        />
                    </div>

                    <FormikProvider value={formik}>
                        <h5>Add the actors:</h5>
                        <FieldArray
                            name="actors"
                            render={arrayhelpers => (
                                <div>
                                    <Paper className="actors_form">
                                        <InputBase
                                            inputRef={actorsValue}
                                            className="input"
                                            placeholder="Add actor name here"
                                        />
                                        <IconButton
                                            onClick={() => {
                                                arrayhelpers.push(actorsValue.current.value)
                                                actorsValue.current.value = ''
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Paper>
                                    { formik.errors.actors && formik.touched.actors ?
                                        <FormHelperText error={true}>
                                            {formik.errors.actors}
                                        </FormHelperText>
                                        : null}

                                    <div className="chip_container">
                                        {formik.values.actors.map((actor, index) => (
                                            <div key={actor}>
                                                <Chip
                                                    label={`${actor}`}
                                                    color="primary"
                                                    onDelete={() => arrayhelpers.remove(index)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        />
                    </FormikProvider>



                    <div className="form-group">
                        <TextField
                            style={{ width: '100%' }}
                            name="director"
                            label="Enter a director"
                            variant="outlined"
                            {...formik.getFieldProps('director')}
                            {...errorHelper(formik, 'director')}
                        />
                    </div>

                    <FormControl variant="outlined">
                        <h5>Select a category</h5>
                        <Select
                            name="category"
                            {...formik.getFieldProps('category')}
                            error={formik.errors.category && formik.touched.category ? true : false}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {categories ?
                                categories.map((item) => (
                                    <MenuItem key={item._id} value={item._id}>
                                        {item.name}
                                    </MenuItem>
                                ))
                                : null}
                        </Select>
                        {formik.errors.category && formik.touched.category ?
                            <FormHelperText error={true}>
                                {formik.errors.category}
                            </FormHelperText>
                            : null}
                    </FormControl>

                    <Divider className="mt-3 mb-3" />

                    <FormControl variant="outlined">
                        <h5>Select a status</h5>
                        <Select
                            name="status"
                            {...formik.getFieldProps('status')}
                            error={formik.errors.status && formik.touched.status ? true : false}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="public">Public</MenuItem>
                            <MenuItem value="draft">Draft</MenuItem>
                        </Select>
                        {formik.errors.status && formik.touched.status ?
                            <FormHelperText error={true}>
                                {formik.errors.status}
                            </FormHelperText>
                            : null}
                    </FormControl>

                    <Divider className="mt-3 mb-3" />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                    // disabled={false}
                    >
                        Add article
                </Button>

                </form>
            }


        </AdminLayout>
    )
}

export default AddArticle;
