import React,{ useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import NavSearch from './search';

import {
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';
// import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';

const SideDrawer = ({users,signOutUser}) => {
    const [state,setState] = useState(false);
    return(
        <>
            <DehazeIcon
                className="drawer_btn"
                onClick={()=> setState(true)}
            />
            <Drawer anchor={'right'} open={state} onClose={()=> setState(false)}>
                {/* <NavSearch
                    closeDrawer={()=> setState(false)}
                /> */}
                {/* <Divider/> */}
                <List>
                    <ListItem button component={RouterLink} to="/" onClick={()=>setState(false)} style={{width:'270px'}}>
                        <ListItemIcon><HomeIcon/></ListItemIcon>
                        <ListItemText primary="首页"/>
                    </ListItem>
                    <ListItem button component={RouterLink} to="/auth/profile" onClick={()=>setState(false)}>
                        <ListItemIcon><PersonIcon/></ListItemIcon>
                        <ListItemText primary="修改账号密码"/>
                    </ListItem>

                    { !users.auth ?
                        <ListItem button component={RouterLink} to="/auth" onClick={()=>setState(false)}>
                            <ListItemIcon><VpnKeyIcon/></ListItemIcon>
                            <ListItemText primary="Sign in"/>
                        </ListItem>
                    :
                        <ListItem button onClick={()=> {
                            signOutUser()
                            setState(false)
                        }}>
                            <ListItemIcon><VpnKeyIcon/></ListItemIcon>
                            <ListItemText primary="退出"/>
                        </ListItem>
                    }

                </List>
                { users.auth && users.data.role === 'admin' ?
                    <>
                        <Divider/>
                        <List>
                            <ListItem button component={RouterLink} to="/dashboard" onClick={()=>setState(false)}>
                                <ListItemIcon><DashboardIcon/></ListItemIcon>
                                <ListItemText primary="Dashboard"/>
                            </ListItem>
                        </List>
                    </>
                :null}
            </Drawer>
        </>
    )
}

export default SideDrawer;
