import React from 'react';
import AdminLayout from '../../hoc/adminLayout';

// import { Player, BigPlayButton } from 'video-react';
const Dashboard = (props) => {
    return(
        <AdminLayout section="Dashboard">
            dashboard
        </AdminLayout>
    )
}

export default Dashboard;
