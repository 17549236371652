import React, { useEffect, useState } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import GoogleFontLoader from 'react-google-font-loader';
import Loader from './utils/loader';
import MainLayout from './hoc/mainLayout';
import AuthGuard from './hoc/authGuard';

import { useDispatch, useSelector } from 'react-redux';
import { isAuthUser } from './store/actions/users_actions';

import Home from './components/home';
import Header from './components/navigation/header';
import Auth from './components/auth';
import AuthProfile from './components/auth/profile';
import Dashboard from './components/dashboard';
import Profile from './components/dashboard/profile';
import Articles from './components/dashboard/articles';
import AddArticle from './components/dashboard/articles/add';
import EditArticle from './components/dashboard/articles/edit';
import Article from './components/articles/article';
import Categories from './components/dashboard/categories';
import SearchResults from './components/search';
import TestUpload from './components/dashboard/testupload'

const Routes = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);

  useEffect(() => {
    dispatch(isAuthUser())
  }, [dispatch]);

  useEffect(() => {
    if (users.auth !== null) {
      setLoading(false)
    }
  }, [users]);

  return (
    <BrowserRouter>
      <Header />

      { loading ?
        <Loader />
        :
        <MainLayout>
          <Switch>
            <Route path="/dashboard/articles/edit/:id" component={AuthGuard(EditArticle, true)} />
            <Route path="/dashboard/articles/add" component={AuthGuard(AddArticle, true)} />
            <Route path="/dashboard/articles" component={AuthGuard(Articles, true)} />
            <Route path="/dashboard/profile" component={AuthGuard(Profile)} />
            <Route path="/dashboard/categories" component={AuthGuard(Categories)} />
            <Route path="/dashboard/testupload" component={AuthGuard(TestUpload)} />
            <Route path="/dashboard" component={AuthGuard(Dashboard)} />
            <Route path="/article/:id/:index" component={AuthGuard(Article)} />
            <Route path="/article/:id" component={AuthGuard(Article)} />
            {/* <Route path="/contact" component={Contact}/> */}
            {/* <Route path="/verification" component={AccountVerify}/> */}
            <Route path="/searchresults?keyword=:keyword" component={AuthGuard(SearchResults)} />
            <Route path="/searchresults" component={AuthGuard(SearchResults)} />
            <Route path="/auth/profile" component={AuthGuard(AuthProfile)} />
            <Route path="/auth" component={Auth} />
            <Route path="/:article_type" component={AuthGuard(Home)} />
            <Route path="/" component={AuthGuard(Home)} />
          </Switch>
        </MainLayout>
      }
      <GoogleFontLoader
        fonts={[
          { font: 'Roboto', weights: [300, 400, 900] },
          { font: 'Fredoka One' }
        ]}
      />
    </BrowserRouter>
  )
}

export default Routes;
