import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Fab,
    // CardActions,
    // IconButton,
    Typography,
    // Button
} from '@material-ui/core';
// import FavoriteIcon from '@material-ui/icons/Favorite';

const ArticleCard = ({ article }) => {
    let image = "https://picsum.photos/200";
    if (article.images && article.images.length > 0) {
        image = article.images[0];
    }
    const max_len = 63;
    const article_content = article.content;
    // console.log(article_content);
    // console.log(article_content.length);
    let content = article_content.substring(0, max_len);
    // console.log(content.length);
    if (article_content.length > max_len) {
        content = content + ' ...';
    }

    let article_type_label;
    switch (article.article_type) {
        case 'movie':
            article_type_label = "电影"
            break;
        case 'tvseries':
            article_type_label = "海外剧"
            break;
        case 'varietyshow':
            article_type_label = "综艺"
            break;
        case 'anime':
            article_type_label = "动漫"
            break;
        case 'chstvseries':
            article_type_label = "国产剧"
            break;
        case 'english':
            article_type_label = "英文学习"
            break;
        default:
            article_type_label = '';
    }
    return (
        <Card>
            <CardActionArea href={`/article/${article._id}/1`}>
                <CardMedia
                    style={{ height: 0, paddingTop: '56.25%', backgroundSize: 'contain'}}
                    image={image}
                    title="some title"
                >
                    <div style={{
                        // minWidth: '45px',
                        position: 'absolute',
                        right: '0',
                        bottom: 'auto',
                    }}>
                        <Fab
                            variant="extended"
                            size="small"
                            style={{
                                background: 'antiquewhite',
                                fontSize: '12px',
                                height: 'auto',
                            }}
                        >
                            {article_type_label}
                        </Fab>
                        {
                            article.article_type !== "english" ?
                                <Fab
                                    variant="extended"
                                    size="small"
                                    style={{
                                        background: 'antiquewhite',
                                        fontSize: '12px',
                                        height: 'auto',
                                    }}
                                >
                                    {article.score}
                                </Fab>
                                : null
                        }
                    </div>
                </CardMedia>
                <CardContent style={{ height: 150 }}>
                    <Typography gutterBottom variant="h6" component="h6">
                        <b>
                            {article.title}
                        </b>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="p"
                        style={{
                            height: '80px',
                            overflow: 'hidden',
                            fontFamily: 'cursive',
                            fontSize: '15px',
                        }}>
                        {content}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}


export default ArticleCard;
