import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getArticle } from '../../../store/actions/article_actions';

import Loader from '../../../utils/loader';
import {
    Grid,
    Button,
    Divider
} from '@material-ui/core';

// import { Player, BigPlayButton, ControlBar, ReplayControl, ForwardControl, VolumeMenuButton, ClosedCaptionButton } from 'video-react';
// import ReactPlayer from 'react-player'
import { Player, ControlBar, BigPlayButton, ClosedCaptionButton, ReplayControl } from 'video-react';

const Article = (props) => {
    const { current } = useSelector(state => state.articles);
    const dispatch = useDispatch();//

    useEffect(() => {
        /// props.match.params.id
        dispatch(getArticle(props.match.params.id))
        // this.props.history.push(`${this.props.history.location.pathname}get-main/123/get-sub/${id}`)
        if (!props.match.params.index) {
            props.history.push(`${props.history.location.pathname}/1`)
        }
    }, [dispatch, props.match.params, props.history]);

    const index = props.match.params.index;
    let url_pathname = props.history.location.pathname;
    if (index) {
        url_pathname = url_pathname.substring(0, url_pathname.lastIndexOf('/'));
    }

    let image = '';
    let video = '';

    if (current && current.images && current.images.length > 0) {
        image = current.images[0];
    }

    // console.log('current');
    // console.log(current);
    let videos = [];
    let eng_sub = null;
    let chs_sub = null;
    let fanti_sub = null;
    let chs_eng_sub = null;
    let eng_chs_sub = null;
    let video_label = '';
    if (current && current.videos && current.videos.length > 0) {
        const video_index = index - 1;
        videos = current.videos;
        video = current.videos[video_index];

        // if (current.eng_subs && video_index < current.eng_subs.length) {
        eng_sub = current.eng_subs[video_index];
        // }
        chs_sub = current.chs_subs[video_index];
        fanti_sub = current.fanti_subs[video_index];
        chs_eng_sub = current.chs_eng_subs[video_index];
        eng_chs_sub = current.eng_chs_subs[video_index];
        video_label = current.video_labels[video_index];
    }

    const formatURL = (url) => {
        if (!url) {
            return url;
        }
        url = url.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
        return url.replace('dl=0', 'raw=1');
    };

    video = formatURL(video);
    eng_sub = formatURL(eng_sub);
    chs_sub = formatURL(chs_sub);
    fanti_sub = formatURL(fanti_sub);
    chs_eng_sub = formatURL(chs_eng_sub);
    eng_chs_sub = formatURL(eng_chs_sub);

    let full_title = current && current.title ? current.title : null;
    if (index && current) {

        if (current.videos && index != current.videos.length) {
            full_title = full_title + ' - 第' + index + '集';
        }
    }
    if (full_title) {
        document.title = full_title;
    }

    let sub_tracks = [];
    if (chs_eng_sub) {
        sub_tracks.push(
            <track key={4} kind="subtitles" label="中上英下" srclang="中上英下" src={chs_eng_sub} default />
        );
    }
    if (eng_chs_sub) {
        sub_tracks.push(
            <track key={5} kind="subtitles" label="英上中下" srclang="英上中下" src={eng_chs_sub} default/>
        );
    }
    if (chs_sub) {
        sub_tracks.push(
            <track key={1} kind="subtitles" label="中文" srclang="chs" src={chs_sub} default />
        );
    }
    if (fanti_sub) {
        sub_tracks.push(
            <track key={3} kind="subtitles" label="繁体" srclang="繁体" src={fanti_sub} default />
        );
    }
    if (eng_sub) {
        sub_tracks.push(
            <track key={2} kind="subtitles" label="English" srclang="en" src={eng_sub} default />
        );
    }
    return (
        <>
            { current ?
                <div style={{
                    // paddingTop: '56.25%',
                }}
                >
                    <Player videoId="video-1" autoPlay crossOrigin="anonymous">
                        <BigPlayButton position="center" />
                        <source
                            src={video}
                            type="video/mp4"
                        />
                        {sub_tracks.length > 0 ? sub_tracks : null}
                        <ControlBar autoHide={true} >
                            <ReplayControl seconds={10} order={2.2} />
                            {sub_tracks.length > 0 ? <ClosedCaptionButton order={7} /> : null}
                        </ControlBar>
                    </Player>

                    {/* <video
                        controls
                        autoplay
                        crossOrigin="anonymous"
                        controlsList="nodownload"
                        poster={image}
                        style={{
                            width: "100%",
                            maxHeight: "600px",
                        }}
                    >
                        <source src={video} type="video/mp4"></source>
                        {sub_tracks.length > 0 ? sub_tracks : null}
                    </video> */}
                    <h3 style={{ marginTop: '10px' }}>{full_title}</h3>
                    <Divider className="mt-3 mb-3" />
                    <h4>{'集数'}</h4>
                    <Grid container spacing={2}>
                        {videos.length > 0 ?
                            videos.map((item, i) => (
                                <Grid key={item._id} item xs={12} sm={6} lg={1}>
                                    <Button key={item._id} href={`${url_pathname}/${i + 1}`} variant={((i + 1) == index) ? "contained" : "outlined"} color="primary">
                                        {
                                            video_label ? (i + 1) + '. ' + video_label : (i + 1)
                                        }
                                    </Button>
                                </Grid>
                            ))
                            : null}
                    </Grid>
                    <Divider className="mt-3 mb-3" />
                    <div>
                        <h4>{'故事简介'}</h4>
                        <div className="mt-3 content">
                            <div dangerouslySetInnerHTML={
                                { __html: current.content }
                            }>
                            </div>
                        </div>
                    </div>
                    {/* Add image thumbnails */}
                    {/* <ScoreCard current={current} /> */}
                </div>
                :
                <Loader />
            }
            {/* <video controls crossOrigin="anonymous"src={video}>
                <track src={eng_sub} kind="subtitles" srclang="en" label="English"></track>
            </video> */}
            {/* <Player src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" autoplay>
          <BigPlayButton position="center" />
        </Player> */}
        </>
    )
}

export default Article;
