import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getSearchNavResults } from '../../store/actions/article_actions';

import {
    TextField,
    Button,
    // Paper,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';


const NavSearch = (props) => {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: { keywords: '' },
        validationSchema: Yup.object({
            keywords: Yup.string()
                .required('Sorry this is required')
                .min(2, 'Just 3 ? come on !!!')
        }),
        onSubmit: (values) => {
            dispatch(getSearchNavResults(1, 5, values.keywords));
            // props.closeDrawer();

            props.history.push(`/searchresults?keywords=${values.keywords}`)
        }
    })

    const errorHelper = (formik, values) => ({
        error: formik.errors[values] && formik.touched[values] ? true : false,
        helperText: formik.errors[values] && formik.touched[values] ? formik.errors[values] : null
    });

    // <form  style={{margin:'20px'}} onSubmit={formik.handleSubmit}>
    // style={{width:'600px'}}
    const useStyles = makeStyles((theme) => ({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '50%',
        },
        input: {
            display: 'inline-flex',
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        form: {
            display: 'flex',
            width: '100%',
        },
    }));

    const classes = useStyles();

    //     return (
    // <Paper component="form" className={classes.root}>
    // <form onSubmit={formik.handleSubmit}>
    //       <InputBase
    //         className={classes.input}
    //         placeholder="Search Google Maps"
    //         inputProps={{ 'aria-label': 'search google maps' }}
    //         name="keywords"
    //       />
    //       <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={formik.handleSubmit}>
    //       {/* <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={()=>{alert('??')}}> */}
    //         <SearchIcon />
    //       </IconButton>
    //       </form>
    //     </Paper>
    //     );
    return (
        <Paper className={classes.root}>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <InputBase
                    className={classes.input}
                    placeholder="搜索"
                    variant="outlined"
                    name="keywords"
                    {...formik.getFieldProps('keywords')}
                />
                <IconButton
                    className={classes.iconButton}
                    variant="contained"
                    color="primary"
                    type="submit"
                    aria-label="search">
                    <SearchIcon />
                </IconButton>
            </form>

        </Paper>
    )

}

export default withRouter(NavSearch);
